import { useCallback, useContext } from 'react';

import { BreakpointsContext } from '../providers/BreakpointsProvider';
import { mediaQueries, MediaQuery } from '../theme/responsivness';

type BreakpointOptions<TOption> = Partial<Record<MediaQuery, TOption>>;

type MatchBreakpoint = <TOption>(options: BreakpointOptions<TOption>) => TOption | undefined;

type UseBreakpoints = () => {
  matchBreakpoint: MatchBreakpoint;
  /**
   * min-width mobile and max-width tablet
   */
  isMobile: boolean;
  /**
   * min-width tablet
   */
  isTablet: boolean;
  /**
   * min-width desktop
   */
  isDesktop: boolean;
};

export const useBreakpoints: UseBreakpoints = () => {
  const breakpointsState = useContext(BreakpointsContext);

  if (!breakpointsState)
    throw new Error("Cannot find 'BreakpointsContext', make sure you use this hook inside its provider.");

  const matchBreakpoint: MatchBreakpoint = useCallback(
    (options) => {
      const breakpointNames = Object.keys(mediaQueries) as MediaQuery[];

      for (const breakpointName of breakpointNames.reverse()) {
        if (options[breakpointName] != null && breakpointsState[breakpointName]) return options[breakpointName];
      }
    },
    [breakpointsState],
  );

  return {
    matchBreakpoint,
    isMobile: breakpointsState.mobile,
    isTablet: breakpointsState.tablet,
    isDesktop: breakpointsState.desktop,
  };
};
