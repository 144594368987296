import { Box } from '@mui/material';
import { List, X } from 'phosphor-react';
import { FC } from 'react';

import borders from '../../../theme/borders';
import palette from '../../../theme/palette';
import IconButton from '../../molecules/IconButton';

export type MenuButtonProps = {
  variant: 'open' | 'close';
  onClick: () => void;
};

export const MenuButton: FC<MenuButtonProps> = ({ variant, onClick }) => {
  const ButtonIcon = variant === 'open' ? List : X;

  return (
    <Box
      padding={2}
      sx={{
        display: 'flex',
        backgroundColor: palette.greyscale[5],
        borderRadius: borders.radii.md,
      }}>
      <IconButton onClick={onClick}>
        <ButtonIcon size={20} />
      </IconButton>
    </Box>
  );
};
