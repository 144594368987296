import { createApi } from '@reduxjs/toolkit/query/react';

import getBaseQuery from '../../../services/getBaseQuery';
import { LoggedInUser } from '../../auth/initialState';

export type MockAuth = {
  authToken: string;
  keycloak: {
    clientId: string;
    realm: string;
    url: string;
  };
  user: LoggedInUser;
};

export const mockAuthApi = createApi({
  reducerPath: 'mockAuthApi',
  baseQuery: getBaseQuery('auth'),
  endpoints: (builder) => ({
    auth: builder.query<MockAuth, void>({
      query: () => ({}),
    }),
  }),
});

export const { useAuthQuery } = mockAuthApi;
