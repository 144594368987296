import { ReduxRouter } from '@lagunovsky/redux-react-router';
import { createBrowserHistory } from 'history';
import { FC, PropsWithChildren } from 'react';
import { Provider } from 'react-redux';

import { getStore } from '../store';

const history = createBrowserHistory();
const store = getStore(history);

export const ReduxProviders: FC<PropsWithChildren> = ({ children }) => (
  <Provider store={store}>
    <ReduxRouter history={history}>{children}</ReduxRouter>
  </Provider>
);
