import type { ErrorComponentProps } from '@virtuslab/react-oauth2';
import { SecurityProvider as OAuthProvider } from '@virtuslab/react-oauth2';
import isNil from 'lodash/isNil';
import type { ReactNode } from 'react';
import { useEffect, useRef } from 'react';
import { Navigate } from 'react-router-dom';

import { GlobalConfig } from '../../../config/global';
import { createUrl } from '../../../services/url';
import Loading from '../../molecules/Loading';

type SendErrorProps = Pick<ErrorComponentProps, 'error'> &
  Readonly<{
    children: ReactNode;
    onError?: (error: NonNullable<ErrorComponentProps['error']>) => void;
  }>;

const SendError = ({ error, children, onError }: SendErrorProps) => {
  const onErrorRef = useRef(onError);
  onErrorRef.current = onError;

  useEffect(() => {
    if (!isNil(error) && onErrorRef.current) {
      onErrorRef.current(error);
    }
  }, [error]);

  return <>{children}</>;
};

type Props = Pick<SendErrorProps, 'onError'> &
  Readonly<{
    children: ReactNode;
    redirectTo: string;
    config: GlobalConfig;
  }>;

const SecurityProvider = ({ children, onError, redirectTo, config }: Props) => (
  <OAuthProvider
    url={config.keycloak.url}
    realm={config.keycloak.realm}
    clientId={config.keycloak.clientID}
    silentCheckSsoRedirectUri={createUrl('/silent-check-sso.html', config.domain)}
    timeout={120000}
    fallback={<Loading />}
    error={({ error }) => (
      <SendError
        error={error}
        onError={onError}>
        <Navigate to={redirectTo} />
      </SendError>
    )}>
    {children}
  </OAuthProvider>
);

export default SecurityProvider;
