const durations = {
  fast: '.15s',
  medium: '.3s',
};

const timingFunctions = {
  fast: 'cubic-bezier(0.65, 0.05, 0.36, 1)',
  medium: 'cubic-bezier(0.4, 0, 0.2, 1)',
};

const animations = {
  durations,
  timingFunctions,
};

export default animations;
