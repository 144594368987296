import { useEffect } from 'react';
import { Navigate } from 'react-router-dom';

import { useAuthenticatedUser } from '../../../../hooks/useAuthenticatedUser';

const Logout = () => {
  const { logout, isAuthenticated } = useAuthenticatedUser();

  useEffect(() => {
    logout('/auth/login');
  }, [logout]);

  if (!isAuthenticated) {
    return <Navigate to="/auth/login" />;
  }

  return <span>Please be patient, we are logging you out</span>;
};

export default Logout;
