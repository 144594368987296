import '@fontsource/montserrat/400.css';
import '@fontsource/montserrat/500.css';
import '@fontsource/montserrat/600.css';

import { css } from 'styled-components/macro';

import { toastContainerId } from '../components/organisms/ToastsContainer';
import theme from '.';
import { htmlFontScale, letterSpacing } from './typography/sizes';

const globalStyle = css`
  * {
    box-sizing: border-box;
    font-family: inherit;
    letter-spacing: inherit;
    margin: ${theme.space[0]};
    padding: ${theme.space[0]};
  }

  html {
    font-size: ${htmlFontScale * 100}%;
  }

  html,
  body,
  body > #root {
    font-family: ${theme.fontFamilies.default}, sans-serif;
    height: 100%;
    letter-spacing: ${letterSpacing};
    overflow: hidden;
  }

  body > #root {
    display: flex;
    margin-left: auto;
    margin-right: auto;
    max-width: ${theme.widths.max.root};
  }

  body[class^='sb-'] > #root {
    height: auto;
  }

  body #${toastContainerId} {
    z-index: ${theme.zindex.toasts};
  }

  a {
    color: inherit;
    text-decoration: none;
  }
`;

export default globalStyle;
