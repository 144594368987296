import './App.css';

import React, { FC } from 'react';
import { Route, Routes } from 'react-router-dom';

import RenderRoutes from './components/organisms/RenderRoutes';
import { AuthProvider, FeaturesProvider, ReduxProviders, ThemeProviders } from './providers';

export const App: FC = () => (
  <ReduxProviders>
    <AuthProvider>
      {/* StrictMode moved from index.tsx due to issue with keycloak:
        https://github.com/react-keycloak/react-keycloak/issues/182 */}
      <React.StrictMode>
        <ThemeProviders>
          <FeaturesProvider>
            <Router />
          </FeaturesProvider>
        </ThemeProviders>
      </React.StrictMode>
    </AuthProvider>
  </ReduxProviders>
);

const Router: FC = () => (
  <Routes>
    <Route
      path="*"
      element={<RenderRoutes />}
    />
  </Routes>
);
