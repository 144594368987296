import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Box from '@mui/material/Box';
import isNil from 'lodash/isNil';
import type { ReactElement } from 'react';
import { useState } from 'react';
import styled from 'styled-components/macro';

import type { NavItem } from '../../../config/navigation';
import useOnClickOutside from '../../../hooks/useOnClickOutside';
import type { LoggedInUser } from '../../../store/auth/initialState';
import Avatar from '../Avatar';
import Body from '../Body';
import Inline404 from '../Inline404';
import NavButton from '../NavButton';
import TextGroup from '../TextGroup';

const StyledAccordion = styled(Accordion)`
  &&& {
    margin: 0;
    padding: 0;

    &.MuiAccordion-root::before {
      display: none;
    }
  }
`;

const StyledAccordionSummary = styled(AccordionSummary)`
  &&& {
    padding: ${({ theme }) => theme.scTheme.space[600]};

    .MuiAccordionSummary-content {
      margin: 0;
    }

    &:hover {
      background-color: ${({ theme }) => theme.scTheme.palette.greyscale[10]};
    }

    &:active {
      background-color: ${({ theme }) => theme.scTheme.palette.greyscale[20]};
    }
  }
`;

const StyledAccordionDetails = styled(AccordionDetails)`
  &&& {
    display: flex;
    flex-direction: column;

    &.MuiAccordionDetails-root {
      padding: ${({ theme }) => theme.scTheme.space[500]} ${({ theme }) => theme.scTheme.space[300]};
      padding-top: 0;
    }
  }
`;

export type Props = Readonly<{
  navigation: readonly NavItem[];
  caption?: string;
  user?: LoggedInUser;
}>;

const ProfileLink = ({ navigation, caption, user }: Props): ReactElement => {
  const [expanded, setExpanded] = useState(false);
  const { ref } = useOnClickOutside<HTMLDivElement>({
    handler: () => setExpanded(false),
  });

  if (isNil(user?.id)) {
    return <Inline404 />;
  }

  return (
    <StyledAccordion
      expanded={expanded}
      ref={ref}>
      <StyledAccordionSummary
        aria-label={expanded ? 'Close profile options menu' : 'Open profile options menu'}
        onClick={() => setExpanded(!expanded)}>
        <Box mr={500}>
          <Avatar user={user!} />
        </Box>
        <TextGroup hideOverflow>
          <Body size={300}>
            {user?.firstName} {user?.lastName}
          </Body>
          <Body
            size={200}
            variant="secondary">
            {caption ?? user?.email}
          </Body>
        </TextGroup>
      </StyledAccordionSummary>
      <StyledAccordionDetails aria-hidden={!expanded}>
        {navigation.map((item) => (
          <Box key={item.id}>
            <NavButton item={item} />
          </Box>
        ))}
      </StyledAccordionDetails>
    </StyledAccordion>
  );
};

export default ProfileLink;
