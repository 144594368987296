import { createApi } from '@reduxjs/toolkit/query/react';
import { prop } from 'lodash/fp';

import { BookHotDeskPayload } from '../../../schemas/BookHotDeskSchema';
import getBaseQuery from '../../../services/getBaseQuery';
import { AvailabilityParameters, HotDeskReservation, ReservationParameters, SpaceAvailability } from './types';

export const hotDeskBookingTag = 'HotDesksReservations' as const;
export const hotDeskAvailabilityTag = 'HotDeskAvailabilityTag' as const;

export const hotDesksApi = createApi({
  reducerPath: 'hotDesksApi',
  baseQuery: getBaseQuery('hotdesks'),
  tagTypes: [hotDeskBookingTag, hotDeskAvailabilityTag],
  endpoints: (builder) => ({
    getReservations: builder.query<HotDeskReservation[], void>({
      query: () => ({ path: 'reservations' }),
      transformResponse: prop('reservations'),
      providesTags: [hotDeskBookingTag],
    }),
    submitForm: builder.mutation<void, BookHotDeskPayload>({
      query: (data: BookHotDeskPayload) => ({
        data,
        method: 'POST',
        path: 'reservations',
      }),
    }),
    submitAction: builder.mutation<HotDeskReservation, ReservationParameters>({
      query: ({ id, action }: ReservationParameters) => ({
        method: 'PATCH',
        path: `reservations/${id}/${action}`,
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data: updatedReservation } = await queryFulfilled;

          dispatch(
            hotDesksApi.util.updateQueryData('getReservations', undefined, (reservations) => {
              reservations.forEach((reservation) => {
                if (reservation.reservationId === updatedReservation.reservationId) {
                  Object.assign(reservation, updatedReservation);
                }
              });
            }),
          );
        } catch {}
      },
    }),
    getAvailability: builder.query<SpaceAvailability[], AvailabilityParameters>({
      query: (params: AvailabilityParameters) => ({
        method: 'GET',
        path: 'availability',
        params,
      }),
      transformResponse: prop('spaces'),
      providesTags: [hotDeskAvailabilityTag],
    }),
  }),
});

export const {
  useSubmitFormMutation,
  useGetReservationsQuery,
  useSubmitActionMutation,
  useGetAvailabilityQuery,
  util: { invalidateTags, updateQueryData },
} = hotDesksApi;
