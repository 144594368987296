import { createApi } from '@reduxjs/toolkit/query/react';

import getBaseQuery from '../../../services/getBaseQuery';
import { BlockDeskArgs, BlockParkingSpotsArgs, Desk, Office, ParkingSpot } from './types';

export * from './types';

function getDesksUpdateQueryData(updatedDesk: Desk) {
  return officesApi.util.updateQueryData('getOfficesWithBlocked', undefined, (offices) => {
    offices.forEach((office) => {
      office.spaces.forEach((space) => {
        space.desks.forEach((desk) => {
          if (desk.id === updatedDesk.id) {
            Object.assign(desk, updatedDesk);
          }
        });
      });
    });
  });
}

function getParkingSpotsUpdateQueryData(updatedParkingSpot: ParkingSpot) {
  return officesApi.util.updateQueryData('getOfficesWithBlocked', undefined, (offices) => {
    offices.forEach((office) => {
      office.parking.parkingSpots.forEach((parkingSpot) => {
        if (parkingSpot.id === updatedParkingSpot.id) {
          Object.assign(parkingSpot, updatedParkingSpot);
        }
      });
    });
  });
}

export const officesApi = createApi({
  reducerPath: 'officesApi',
  baseQuery: getBaseQuery('offices'),
  endpoints: (builder) => ({
    getOffices: builder.query<Office[], void>({
      query: () => ({}),
      transformResponse: (res: { offices: Office[] }) => res.offices,
    }),
    getOfficesWithBlocked: builder.query<Office[], void>({
      query: () => ({ params: { withBlocked: 'true' } }),
      transformResponse: (res: { offices: Office[] }) => res.offices,
    }),
    getOfficeMap: builder.query<string, string>({
      query: (officeId) => ({ path: `${officeId}/map`, responseType: 'blob' }),
    }),
    getParkingMap: builder.query<string, string>({
      query: (officeId) => ({ path: `${officeId}/parking-map`, responseType: 'blob' }),
    }),
    blockDesk: builder.mutation<Desk, BlockDeskArgs>({
      query: ({ officeId, spaceId, deskId }) => ({
        method: 'POST',
        path: `${officeId}/spaces/${spaceId}/desks/${deskId}/block`,
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data: updatedDesk } = await queryFulfilled;
          dispatch(getDesksUpdateQueryData(updatedDesk));
        } catch {}
      },
    }),
    unblockDesk: builder.mutation<Desk, BlockDeskArgs>({
      query: ({ officeId, spaceId, deskId }) => ({
        method: 'POST',
        path: `${officeId}/spaces/${spaceId}/desks/${deskId}/unblock`,
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data: updatedDesk } = await queryFulfilled;
          dispatch(getDesksUpdateQueryData(updatedDesk));
        } catch {}
      },
    }),
    blockParkingSpot: builder.mutation<ParkingSpot, BlockParkingSpotsArgs>({
      query: ({ officeId, spotId }) => ({
        method: 'POST',
        path: `${officeId}/spots/${spotId}/block`,
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data: updatedParkingSpot } = await queryFulfilled;
          dispatch(getParkingSpotsUpdateQueryData(updatedParkingSpot));
        } catch {}
      },
    }),
    unblockParkingSpot: builder.mutation<ParkingSpot, BlockParkingSpotsArgs>({
      query: ({ officeId, spotId }) => ({
        method: 'POST',
        path: `${officeId}/spots/${spotId}/unblock`,
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data: updatedParkingSpot } = await queryFulfilled;
          dispatch(getParkingSpotsUpdateQueryData(updatedParkingSpot));
        } catch {}
      },
    }),
  }),
});

export const {
  useGetOfficesQuery,
  useGetOfficesWithBlockedQuery,
  useGetOfficeMapQuery,
  useGetParkingMapQuery,
  useBlockDeskMutation,
  useUnblockDeskMutation,
  useBlockParkingSpotMutation,
  useUnblockParkingSpotMutation,
} = officesApi;
