const palette = {
  greyscale: {
    5: '#F9F9F9',
    10: '#F4F4F4',
    20: '#E0E0E0',
    30: '#C6C6C6',
    40: '#A9A9A9',
    50: '#8D8D8D',
    60: '#6F6F6F',
    70: '#525252',
    80: '#3A3A3A',
    90: '#262626',
    100: '#171717',
  },
  primary: {
    10: '#E9F8F0',
    20: '#BFEAD5',
    30: '#86D7AF',
    60: '#2A7E55',
    70: '#1F5D3E',
    80: '#16412C',
  },
  support: {
    alert: {
      10: '#FDF2F2',
      20: '#F8D9D9',
      30: '#F3B8B8',
      60: '#D72626',
      70: '#A11C1C',
      80: '#721414',
    },
    info: {
      10: '#F0F4FB',
      20: '#D6E0F5',
      30: '#B5C7EC',
      40: '#8CA9E2',
      60: '#3B6CCD',
    },
    success: {
      10: '#E9F8E9',
      20: '#C2EBC2',
      30: '#8BD88B',
      60: '#2A7F2A',
    },
    suggestion: {
      10: '#FDF4D7',
      20: '#F0E6BE',
      30: '#F1C119',
      40: '#CEA30D',
    },
    warning: {
      10: '#FFF2E9',
      20: '#FFD9BE',
      30: '#FFB784',
      40: '#FF8933',
      50: '#EC6300',
      60: '#BA4E00',
      70: '#8A3A00',
    },
  },
  additional: {
    purple: {
      70: '#513DB6',
    },
    teal: {
      20: '#BBE8E8',
      50: '#349B9B',
    },
    magenta: {
      60: '#CF2670',
    },
  },
  avatars: {
    marguerite: '#715FC9',
    paradiso: '#297A7A',
    cerise: '#CF2670',
    sharon: '#BA4E00',
    saintTropez: '#284F9D',
    corn: '#886C08',
  },
  dataViz: ['#513DB6', '#349B9B', '#CF2670', '#678CD8', '#BA4E00', '#36A236', '#E46565', '#F1C119'],
  inverted: {
    20: 'rgba(255,255,255,0.15)',
    30: 'rgba(255,255,255,0.23)',
    40: 'rgba(255,255,255,0.32)',
    70: 'rgba(255,255,255,0.66)',
    80: 'rgba(255,255,255,0.78)',
    90: 'rgba(255,255,255,0.88)',
    100: 'rgba(255,255,255,0.96)',
    white: '#FFFFFF',
  },
  transparent: 'transparent',
} as const;

export type Palette = typeof palette;

export default palette;
