import { Box, Typography } from '@mui/material';
import { GoogleLogo } from 'phosphor-react';
import qs from 'qs';
import { Navigate, useLocation } from 'react-router-dom';
import styled from 'styled-components/macro';

import background from '../../../../assets/images/backgrounds/dusted.svg';
import { useAuthenticatedUser } from '../../../../hooks/useAuthenticatedUser';
import CTAButton from '../../../molecules/CTAButton';
import Header from '../../../molecules/Header';
import Icon from '../../../molecules/Icon';
import OmLogo from '../../../molecules/OmLogo';

const LoginContainer = styled.div`
  display: grid;
  grid-template-areas:
    'empty'
    'login_text'
    'background';
  grid-template-columns: 1fr;
  grid-template-rows: 1fr auto 1fr;
  height: 100%;
  justify-items: center;
  width: 100%;
`;

const LoginBackgroundDiv = styled.div`
  background-image: url(${background});
  background-position-x: center;
  background-position-y: top;
  background-repeat: no-repeat;
  background-size: cover;
  grid-area: background;
  height: 100%;
  width: 100%;

  @media screen and (min-width: 1600px) {
    position: absolute;
    top: 20%;
    z-index: -1;
  }
  @media screen and (min-width: 1200px) and (max-width: 1600px) {
    position: absolute;
    top: 30%;
    z-index: -1;
  }

  @media screen and (max-width: 1200px) {
    position: absolute;
    top: 40%;
    z-index: -1;
  }
`;

const Login = () => {
  const { isAuthenticated, login } = useAuthenticatedUser();
  const { search } = useLocation();
  const { redirectUri = '/' } = qs.parse(search, { ignoreQueryPrefix: true });
  const redirectAfterLogin = `${String(redirectUri)}`;

  if (isAuthenticated) {
    return <Navigate to={redirectAfterLogin} />;
  }

  return (
    <LoginContainer>
      <Box
        position="absolute"
        top={0}
        left={0}
        p={600}>
        <OmLogo />
      </Box>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
        width="max-content"
        gridArea="login_text">
        <Box p={600}>
          <Header as="h1">Log in</Header>
        </Box>
        <Box py={600}>
          <CTAButton
            fullWidth
            variant="primary"
            onClick={() => login(`${redirectAfterLogin}`)}>
            <Box
              px={300}
              display="inline-flex">
              <Box
                px={700}
                display="inline-flex"
                alignItems="center">
                <Box
                  display="inline-flex"
                  mr={300}>
                  <Icon size="lg">
                    <GoogleLogo weight="bold" />
                  </Icon>
                </Box>
                <Typography fontSize="1.6rem">Continue with Google</Typography>
              </Box>
            </Box>
          </CTAButton>
        </Box>
      </Box>
      <LoginBackgroundDiv />
    </LoginContainer>
  );
};

export default Login;
