import SecureRoutesWrapper from '../../components/organisms/SecureRoutesWrapper';
import ErrorPage from '../../components/pages/CommonPages/ErrorPage';
import Login from '../../components/pages/CommonPages/Login';
import Logout from '../../components/pages/CommonPages/Logout';
import { ArrayOfRoutes, Route } from './types';

export const CommonRoutes: ArrayOfRoutes = [
  {
    id: 'LOGIN',
    title: 'Login',
    path: '/auth/login',
    component: Login,
  },
  {
    id: 'LOGOUT',
    title: 'Logout',
    path: '/auth/logout',
    component: Logout,
  },
  {
    id: 'error-404',
    title: 'Error 404',
    path: '*',
    component: ErrorPage,
  },
];

export const DefaultRoutes: Route = {
  id: 'BASE',
  title: 'Base',
  path: '/',
  component: SecureRoutesWrapper,
};
