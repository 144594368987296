import { FC, PropsWithChildren } from 'react';

import { AuthenticatedUserProvider } from '../components/organisms/AuthenticatedUserProvider';
import SecurityProvider from '../components/organisms/SecurityProvider';
import { GlobalConfig, KeycloakConfig } from '../config/global';
import { isAllRequired, isRequired } from '../services/guards';
import { MockAuthProvider } from './MockAuthProvider';

const isLocalDevelopment = process.env.REACT_APP_ENV === 'local';

export const AuthProvider: FC<PropsWithChildren> = ({ children }) => {
  if (isLocalDevelopment) return <MockAuthProvider children={children} />;

  const config = getAuthConfig();

  return (
    <SecurityProvider
      redirectTo="/auth/login"
      config={config}>
      <AuthenticatedUserProvider config={config}>{children}</AuthenticatedUserProvider>
    </SecurityProvider>
  );
};

const {
  REACT_APP_API_PREFIX,
  REACT_APP_KEYCLOAK_URL,
  REACT_APP_KEYCLOAK_REALM,
  REACT_APP_KEYCLOAK_CLIENTID,
  REACT_APP_DOMAIN,
} = process.env;

const getAuthConfig = (): GlobalConfig => ({
  keycloak: isAllRequired<KeycloakConfig>(
    {
      url: REACT_APP_KEYCLOAK_URL,
      realm: REACT_APP_KEYCLOAK_REALM,
      clientID: REACT_APP_KEYCLOAK_CLIENTID,
    },
    'keycloak',
  ),
  apiPrefix: isRequired<string>('Api prefix is not configured', REACT_APP_API_PREFIX),
  domain: isRequired<string>('Application domain need to be set in production environment!', REACT_APP_DOMAIN),
});
