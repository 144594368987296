import palette from './palette';

const widths = {
  thin: '.1rem',
  medium: '.2rem',
  active: '.2rem',
  focus: {
    inner: '.1rem',
    outer: '.4rem',
  },
  dashed: '.1rem',
};

const borders = {
  radii: {
    xs: '.1rem',
    sm: '.2rem',
    md: '.4rem',
    lg: '.8rem',
    xl: '1rem',
    xxl: '1.8rem',
  },
  widths,
  styles: {
    active: {
      primary: `${widths.active} solid ${palette.primary[60]}`,
    },
    focus: {
      grey: `${widths.focus.inner} solid ${palette.greyscale[30]}`,
      primary: `${widths.focus.inner} solid ${palette.primary[30]}`,
      alert: `${widths.focus.inner} solid ${palette.support.alert[30]}`,
      inverted: `${widths.focus.inner} solid ${palette.inverted[30]}`,
      warning: `${widths.focus.inner} solid ${palette.support.warning[30]}`,
    },
    medium: {
      inverted: {
        white: `${widths.medium} solid ${palette.inverted.white}`,
      },
    },
    thin: {
      transparent: `${widths.thin} solid transparent`,
      grey: {
        5: `${widths.thin} solid ${palette.greyscale[5]}`,
        10: `${widths.thin} solid ${palette.greyscale[10]}`,
        20: `${widths.thin} solid ${palette.greyscale[20]}`,
        40: `${widths.thin} solid ${palette.greyscale[40]}`,
        60: `${widths.thin} solid ${palette.greyscale[60]}`,
        90: `${widths.thin} solid ${palette.greyscale[90]}`,
      },
      primary: {
        20: `${widths.thin} solid ${palette.primary[20]}`,
        30: `${widths.thin} solid ${palette.primary[30]}`,
        60: `${widths.thin} solid ${palette.primary[60]}`,
        70: `${widths.thin} solid ${palette.primary[70]}`,
        80: `${widths.thin} solid ${palette.primary[80]}`,
      },
      alert: {
        20: `${widths.thin} solid ${palette.support.alert[20]}`,
        30: `${widths.thin} solid ${palette.support.alert[30]}`,
        60: `${widths.thin} solid ${palette.support.alert[60]}`,
        70: `${widths.thin} solid ${palette.support.alert[70]}`,
        80: `${widths.thin} solid ${palette.support.alert[80]}`,
      },
      info: {
        20: `${widths.thin} solid ${palette.support.info[20]}`,
        60: `${widths.thin} solid ${palette.support.info[60]}`,
      },
      success: {
        20: `${widths.thin} solid ${palette.support.success[20]}`,
        60: `${widths.thin} solid ${palette.support.success[60]}`,
      },
      suggestion: {
        20: `${widths.thin} solid ${palette.support.suggestion[20]}`,
        30: `${widths.thin} solid ${palette.support.suggestion[30]}`,
      },
      warning: {
        20: `${widths.thin} solid ${palette.support.warning[20]}`,
        30: `${widths.thin} solid ${palette.support.warning[30]}`,
        60: `${widths.thin} solid ${palette.support.warning[60]}`,
        70: `${widths.thin} solid ${palette.support.warning[70]}`,
      },
    },
    dashed: {
      grey: {
        20: `${widths.dashed} dashed ${palette.greyscale[20]}`,
        30: `${widths.dashed} dashed ${palette.greyscale[30]}`,
        60: `${widths.dashed} dashed ${palette.greyscale[60]}`,
      },
      info: {
        60: `${widths.dashed} dashed ${palette.support.info[60]}`,
      },
      warning: {
        30: `${widths.dashed} dashed ${palette.support.warning[30]}`,
        60: `${widths.dashed} dashed ${palette.support.warning[60]}`,
        70: `${widths.dashed} dashed ${palette.support.warning[70]}`,
      },
      alert: {
        60: `${widths.dashed} dashed ${palette.support.alert[60]}`,
      },
      success: {
        60: `${widths.dashed} dashed ${palette.support.success[60]}`,
      },
      primary: {
        30: `${widths.dashed} dashed ${palette.primary[30]}`,
        60: `${widths.dashed} dashed ${palette.primary[60]}`,
      },
    },
  },
} as const;

export default borders;
