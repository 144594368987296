import { createContext } from 'react';

export type AuthenticatedUserContextType = {
  login: (target: string) => void;
  logout: (target: string) => void;
};

export const AuthenticatedUserContext = createContext<AuthenticatedUserContextType>({
  login: () => {},
  logout: () => {},
});
