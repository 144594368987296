import { createContext, useContext } from 'react';

export type FeaturesType = {
  multiSelection: boolean;
  hotDeskBookingNotes: boolean;
  parkingSpotBookingNotes: boolean;
};

const Context = createContext<FeaturesType>({
  multiSelection: false,
  hotDeskBookingNotes: false,
  parkingSpotBookingNotes: true,
});

export const FeaturesContextProvider = Context.Provider;

export const useFeatures = () => {
  const features = useContext(Context);

  if (!features) {
    throw new Error('Features provider is missing');
  }

  return features;
};
