import type { ReactElement } from 'react';

import { Route } from '../constants/routes/types';
import generateNavItem from '../services/generateNavigation';

export type NavItem = Readonly<{
  id: string;
  title: string;
  icon?: ReactElement;
  url: string;
  disabled?: boolean;
  associatedRoutes?: string[];
}>;

export type NavGroup = Readonly<{
  id: string;
  title?: string;
  items: readonly NavItem[];
  defaultUrl: string;
}>;

const getNavigationItems = async (userPermissions: string): Promise<NavItem[]> =>
  await import(`../constants/routes/${userPermissions}_ROUTES`).then((modules) =>
    modules.default.map((route: Route) => generateNavItem(route)),
  );

const Navigation = async (permission: string | undefined): Promise<NavGroup[]> => {
  if (!permission) {
    return [];
  }

  const navItems = await getNavigationItems(permission);

  return [
    {
      id: 'global',
      items: navItems,
      defaultUrl: navItems[0]?.url,
    },
  ];
};

export const profileNavigation = (): NavItem[] => [
  {
    id: 'settings',
    title: 'Accounts settings',
    url: '/auth/login',
    disabled: true,
  },
  {
    id: 'logout',
    title: 'Logout',
    url: '/auth/logout',
  },
];
export default Navigation;
