import isNil from 'lodash/isNil';
import type { ReactElement } from 'react';
import { Suspense, useEffect, useState } from 'react';
import { Outlet, useMatch, useNavigate } from 'react-router-dom';

import navigation, { NavGroup, profileNavigation } from '../../../config/navigation';
import { DefaultRoutes } from '../../../constants/routes/COMMON_ROUTES';
import { useAuthenticatedUser } from '../../../hooks/useAuthenticatedUser';
import Loading from '../../molecules/Loading';
import ProfileLink from '../../molecules/ProfileLink';
import { NavigationMenu } from '../NavSidebar/navigation-menu';
import ToastsContainer from '../ToastsContainer';

const SecureRoutesWrapper = (): ReactElement => {
  const navigate = useNavigate();
  const { user } = useAuthenticatedUser();
  const [navItems, setNavItems] = useState<NavGroup[]>([]);
  const isInRoot = !isNil(useMatch(DefaultRoutes.path));

  useEffect(() => {
    const getNavigation = async () => {
      const data = await navigation(user?.permission);
      setNavItems(data);
    };

    getNavigation();
  }, [user?.permission]);

  useEffect(() => {
    if (isInRoot && navItems.length > 0) {
      navigate(navItems[0]?.defaultUrl);
    }
  }, [isInRoot, navItems, navigate]);

  return (
    <>
      <ToastsContainer />
      <NavigationMenu
        options={navItems}
        profileLink={
          <ProfileLink
            user={user}
            navigation={profileNavigation()}
          />
        }
      />
      <Suspense fallback={<Loading />}>
        <Outlet />
      </Suspense>
    </>
  );
};

export default SecureRoutesWrapper;
