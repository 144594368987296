import 'react-toastify/dist/ReactToastify.css';

import type { ReactElement } from 'react';
import { useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';
import type { ToastContainerProps } from 'react-toastify';
import { ToastContainer } from 'react-toastify';
import styled from 'styled-components/macro';

import ToastCloseButton from '../../molecules/ToastCloseButton';
import ToastIcon from '../../molecules/ToastIcon';

const StyledToastsContainer = styled.div`
  bottom: 0;
  padding: ${({ theme }) => theme.scTheme.space[600]};
  pointer-events: none;
  position: absolute;
  z-index: ${({ theme }) => theme.scTheme.zindex.toasts};

  .Toastify__toast-container {
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.scTheme.space[500]};
    width: max-content;

    @media ${({ theme }) => theme.scTheme.mediaQueries.mobile} {
      top: unset;
      bottom: 1em;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  .Toastify__toast {
    align-items: center;
    background-color: ${({ theme }) => theme.scTheme.palette.inverted.white};
    border: ${({ theme }) => theme.scTheme.borders.styles.thin.grey[20]};
    border-radius: ${({ theme }) => theme.scTheme.borders.radii.md};
    box-shadow: ${({ theme }) => theme.scTheme.shadows.largeShadow};
    display: flex;
    font-family: inherit;
    margin: 0;
    min-height: min-content;
    padding: ${({ theme }) => theme.scTheme.space[500]};
    pointer-events: all;
  }

  .Toastify__toast > button {
    margin-left: ${({ theme }) => theme.scTheme.space[500]};
  }

  .Toastify__toast-icon + div {
    width: 100%;
  }

  .Toastify__toast-body {
    align-items: center;
    display: flex;
    flex-direction: row;
    flex-shrink: 0;
    margin: 0;
    padding: 0;
  }
`;

type Props = Omit<ToastContainerProps, 'theme'>;

export const toastContainerId = 'toast-container';

const Toasts = (props: Props): ReactElement => {
  const node = useRef<HTMLDivElement>(document.createElement('div'));

  useEffect(() => {
    node.current.id = toastContainerId;
    document.body.appendChild(node.current);
  }, []);

  return createPortal(
    <StyledToastsContainer>
      <ToastContainer
        position="top-right"
        hideProgressBar
        closeButton={ToastCloseButton}
        icon={ToastIcon}
        {...props}
      />
    </StyledToastsContainer>,
    node.current,
  );
};

export default Toasts;
