import has from 'lodash/has';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import isObject from 'lodash/isObject';
import isPlainObject from 'lodash/isPlainObject';
import type { FieldError } from 'react-hook-form';

import type { DeepObjectPaths, ShallowReplaceNullable } from './object';

export type ExcludeNil<T> = T extends undefined ? never : T extends null ? never : T;

export const isNotNil = <T>(arg: T): arg is ExcludeNil<T> => !isNil(arg);

export type Literal = Record<string, unknown>;
export const isLiteral = <T>(arg: unknown): arg is T extends Literal ? T : Literal => isPlainObject(arg);

export const isObjectDeepPath = <T extends Literal>(obj: T, path: number | string): path is DeepObjectPaths<T> =>
  has(obj, path);

export const isError = (arg: unknown): arg is FieldError => isLiteral(arg) && 'ref' in arg && 'message' in arg;

export const isArrayOf = <T>(arg: unknown, guard: (item: unknown) => item is T): arg is T[] =>
  Array.isArray(arg) && arg.every(guard);

export const isObjectWithKeys = <T>(arg: unknown, requiredKeys: (keyof T)[]): arg is T =>
  isObject(arg) && requiredKeys.every((key) => key in arg);

export const isObjectWithPartialKeys = <T>(arg: unknown, requiredKeys: (keyof T)[]): arg is Partial<T> =>
  isObject(arg) && requiredKeys.some((key) => key in arg);

export const isShallowNonNullableObject = <T extends Literal>(
  data: T | undefined,
): data is ShallowReplaceNullable<T> => {
  if (isNil(data)) {
    return false;
  }

  return Object.values(data).every(isNotNil) && Object.keys(data).length !== 0;
};

export const isHTMLElement = (arg: unknown): arg is HTMLElement => arg instanceof HTMLElement;

export function isRequired<T>(errorMessage: string, value: T | undefined): T {
  if (isEmpty(value)) {
    throw Error(`Config Error: ${errorMessage}`);
  }

  return value!;
}

export function isAllRequired<T extends Record<string, any>>(
  records: Partial<T>,
  parent: string | undefined = undefined,
): T {
  return Object.entries(records).reduce(
    (carry, [prop, value]) => ({
      ...carry,
      [prop]: isRequired(`Value for ${parent ? parent + '.' : ''}${prop} was not provided!`, value),
    }),
    {} as T,
  );
}

export function assertNotNil<T>(x: T): asserts x is ExcludeNil<T> {
  if (isNotNil(x)) {
    return;
  }

  throw new Error('Non-nil value expected!');
}
