import { SecurityContext } from '@virtuslab/react-oauth2';
import KeycloakAdapter from '@virtuslab/react-oauth2/lib/auth/KeycloakAdapter';
import { FC, PropsWithChildren, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import Loading from '../components/molecules/Loading';
import { MockAuth, useAuthQuery } from '../store/api/mock-auth/mockAuthApi';
import { setAuthUser, setAuthUserToken } from '../store/auth/actions';

const MockAuthProviderContainer: FC<PropsWithChildren> = ({ children }) => {
  const { data, isLoading, isFetching } = useAuthQuery();

  if (isLoading || isFetching) return <Loading />;

  return (
    <MockAuthProvider
      auth={data!}
      children={children}
    />
  );
};

type MockAuthProviderProps = {
  auth: MockAuth;
};

const MockAuthProvider: FC<PropsWithChildren<MockAuthProviderProps>> = ({ auth, children }) => {
  const dispatch = useDispatch();

  const { authToken, keycloak, user } = auth;

  const keycloakAdapter = new KeycloakAdapter(keycloak);

  useEffect(() => {
    dispatch(setAuthUserToken(authToken));
    dispatch(setAuthUser(user));
  }, [authToken, dispatch, user]);

  return (
    <SecurityContext.Provider
      value={{ auth: keycloakAdapter, fallback: <Loading />, errorComponent: () => <>oops there is an error</> }}>
      {children}
    </SecurityContext.Provider>
  );
};

export { MockAuthProviderContainer as MockAuthProvider };
