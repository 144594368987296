import { createSlice } from '@reduxjs/toolkit';

import { RESPONSE_STATE } from '../types';
import { setAuthStateAction, setAuthUser, setAuthUserToken } from './actions';
import { initialState } from './initialState';

const auth = createSlice({
  initialState,
  name: 'auth',
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(setAuthStateAction, (state, action) => {
        state.state = action.payload;
      })
      .addCase(setAuthUser, (state, action) => {
        state.user = action.payload;

        if (action.payload) {
          state.state = RESPONSE_STATE.loaded;
        } else {
          state.state = RESPONSE_STATE.notInitialized;
        }
      })
      .addCase(setAuthUserToken, (state, action) => {
        state.token = action.payload;
      });
  },
});

export default auth.reducer;
