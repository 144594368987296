import { createApi } from '@reduxjs/toolkit/query/react';

import { BookPartingSpotInput } from '../../../schemas/BookParkingSpotSchema';
import getBaseQuery from '../../../services/getBaseQuery';
import { ParkingSpotReservation } from './types';

type ReservationParameters = {
  id: string;
  action: string;
};

export const parkingsApi = createApi({
  reducerPath: 'parkingsApi',
  baseQuery: getBaseQuery('parkings'),
  tagTypes: ['ParkingReservations'],
  endpoints: (builder) => ({
    getReservations: builder.query<ParkingSpotReservation[], void>({
      query: () => ({ path: 'reservations' }),
      providesTags: ['ParkingReservations'],
      transformResponse: (res: { reservations: ParkingSpotReservation[] }) => res.reservations,
    }),
    submitForm: builder.mutation<void, BookPartingSpotInput>({
      query: (formData: BookPartingSpotInput) => ({
        path: 'reservations',
        method: 'POST',
        data: formData,
      }),
    }),
    submitAction: builder.mutation<ParkingSpotReservation, ReservationParameters>({
      query: ({ id, action }: ReservationParameters) => ({
        path: `reservations/${id}/${action}`,
        method: 'PATCH',
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data: updatedReservation } = await queryFulfilled;

          dispatch(
            parkingsApi.util.updateQueryData('getReservations', undefined, (reservations) => {
              reservations.forEach((reservation) => {
                if (reservation.reservationId === updatedReservation.reservationId) {
                  Object.assign(reservation, updatedReservation);
                }
              });
            }),
          );
        } catch {}
      },
    }),
  }),
});

export const {
  useSubmitFormMutation,
  useGetReservationsQuery,
  useSubmitActionMutation,
  util: { invalidateTags },
} = parkingsApi;
