import { createTheme } from '@mui/material/styles';

import theme from '.';
import type { Breakpoint } from './responsivness';
import type { Space } from './space';
import { spaceFactor } from './space';
import { htmlFontSize } from './typography/sizes';

const muiTheme = createTheme({
  components: {
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          '&.Mui-disabled': {
            opacity: 1,
          },
          '&.MuiButtonBase-root.Mui-disabled': {
            color: theme.palette.greyscale[40],
          },
        },
      },
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiCheckbox: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiButton: {
      defaultProps: {
        disableElevation: true,
      },
    },
    MuiInput: {
      defaultProps: {
        autoComplete: 'off',
        disableUnderline: true,
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          overflow: 'visible',
          whiteSpace: 'break-spaces',
        },
      },
    },
    MuiCircularProgress: {
      defaultProps: {
        size: theme.widths.default.spinner,
      },
    },
    MuiAutocomplete: {
      defaultProps: {
        openOnFocus: true,
        clearOnBlur: true,
      },
      styleOverrides: {
        root: {
          width: '100%',
        },
        paper: {
          boxShadow: theme.shadows.largeShadow,
          marginTop: theme.space[300],
        },
        listbox: {
          maxHeight: '24rem',
        },
        option: {
          '&.Mui-focused.Mui-focused': {
            backgroundColor: theme.palette.greyscale[10],
          },
          '&.MuiAutocomplete-option[aria-selected="true"]': {
            backgroundColor: theme.palette.greyscale[10],
          },
          '&.Mui-focused.MuiAutocomplete-option[aria-selected="true"]': {
            backgroundColor: theme.palette.greyscale[10],
          },
          '&.Mui-focused.Mui-focused[aria-disabled="true"]': {
            backgroundColor: theme.palette.inverted.white,
          },
          '&[aria-disabled="true"]': {
            opacity: 1,
            cursor: 'not-allowed',
          },
        },
      },
    },
    MuiSkeleton: {
      styleOverrides: {
        root: {
          transform: 'unset',
        },
        text: {
          transform: undefined,
        },
      },
    },
    MuiBackdrop: {
      styleOverrides: {
        root: {
          // backgroundColor: theme.palette.inverted[100],
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          background: 'transparent',
          boxShadow: 'none',
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        clickable: {
          '&:active': {
            boxShadow: undefined,
          },
        },
      },
    },
    MuiAvatar: {
      styleOverrides: {
        colorDefault: {
          backgroundColor: theme.palette.avatars.saintTropez,
        },
      },
    },
    MuiList: {
      styleOverrides: {
        padding: {
          paddingTop: undefined,
          paddingBottom: undefined,
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          backgroundColor: theme.palette.greyscale[20],
          borderColor: theme.palette.greyscale[20],
        },
        absolute: {
          '&.MuiDivider-vertical': {
            height: '100vh',
            top: '50%',
            transform: 'translateY(-50%)',
            width: 'unset',
          },
          '&:not(.MuiDivider-vertical)': {
            width: '100vw',
            left: '50%',
            transform: 'translateX(-50%)',
          },
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          maxWidth: theme.widths.max.tooltip,
          padding: `${theme.space[100]} ${theme.space[300]}`,
          boxShadow: theme.shadows.largeShadow,
          backgroundColor: theme.palette.inverted.white,
          overflow: 'hidden',
          '&:not(.light)': {
            backgroundColor: theme.palette.greyscale[90],
          },
          fontSize: theme.fontSizes[300],
          lineHeight: theme.fontLineHeights.md,
        },
        tooltipPlacementTop: {
          margin: `${theme.space[300]} ${theme.space[0]}`,
          [`@media ${theme.mediaQueries.tablet}`]: {
            margin: `${theme.space[300]} ${theme.space[0]}`,
          },
        },
        tooltipPlacementBottom: {
          margin: `${theme.space[300]} ${theme.space[0]}`,
          [`@media ${theme.mediaQueries.tablet}`]: {
            margin: `${theme.space[300]} ${theme.space[0]}`,
          },
        },
        tooltipPlacementLeft: {
          margin: `${theme.space[0]} ${theme.space[300]}`,
          [`@media ${theme.mediaQueries.tablet}`]: {
            margin: `${theme.space[0]} ${theme.space[300]}`,
          },
        },
        tooltipPlacementRight: {
          margin: `${theme.space[0]} ${theme.space[300]}`,
          [`@media ${theme.mediaQueries.tablet}`]: {
            margin: `${theme.space[0]} ${theme.space[300]}`,
          },
        },
      },
    },
  },
  spacing: (factor: number | string) => {
    if (factor in theme.space) {
      return theme.space[factor as Space];
    }

    return Number(factor) * spaceFactor;
  },
  typography: {
    button: {
      textTransform: 'none',
    },
    fontWeightRegular: theme.fontWeights.regular,
    fontWeightMedium: theme.fontWeights.medium,
    fontWeightBold: theme.fontWeights.bold,
    fontSize: parseInt(theme.fontSizes[400], 10),
    fontFamily: theme.fontFamilies.default,
    htmlFontSize,
  },
  breakpoints: {
    keys: ['xs', 'sm', 'md', 'lg', 'xl'],
    up: (key) => `@media (min-width:${theme.breakpoints[key as Breakpoint]}px)`,
  },
  zIndex: {
    drawer: theme.zindex.drawers,
    modal: theme.zindex.modals,
  },
  palette: {
    text: {
      primary: theme.palette.greyscale[80],
    },
    grey: {
      100: theme.palette.greyscale[10],
      200: theme.palette.greyscale[20],
      300: theme.palette.greyscale[30],
      400: theme.palette.greyscale[40],
      500: theme.palette.greyscale[50],
      600: theme.palette.greyscale[60],
      700: theme.palette.greyscale[80],
      800: theme.palette.greyscale[90],
      900: theme.palette.greyscale[100],
    },
    action: {
      hover: theme.palette.primary[70],
      active: theme.palette.primary[80],
      disabled: theme.palette.greyscale[40],
      disabledBackground: 'red',
    },
    primary: {
      light: theme.palette.primary[30],
      main: theme.palette.primary[60],
      dark: theme.palette.primary[70],
    },
    secondary: {
      light: theme.palette.primary[30],
      main: theme.palette.primary[60],
      dark: theme.palette.primary[70],
    },
    error: {
      light: theme.palette.support.alert[30],
      main: theme.palette.support.alert[60],
      dark: theme.palette.support.alert[80],
    },
    success: {
      light: theme.palette.support.success[10],
      main: theme.palette.support.success[60],
    },
    warning: {
      light: theme.palette.support.warning[10],
      main: theme.palette.support.warning[40],
    },
    info: {
      light: theme.palette.support.info[10],
      main: theme.palette.support.info[60],
    },
  },
});

export default muiTheme;
