import { CircularProgress } from '@mui/material';
import Fade from '@mui/material/Fade';
import type { ReactElement } from 'react';
import styled from 'styled-components/macro';

const Container = styled.div`
  align-items: center;
  display: flex;
  height: 100vh;
  justify-content: center;
  width: 100vw;
`;

const Loading = (): ReactElement => (
  <Container>
    <Fade
      in
      style={{
        transitionDelay: '150ms',
      }}>
      <CircularProgress color="primary" />
    </Fade>
  </Container>
);

export default Loading;
