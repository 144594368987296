import { RouteObject } from 'react-router-dom';

import { Route } from '../constants/routes/types';

const GenerateRoute = ({ path, component: Component, children }: Route): RouteObject => ({
  path: path,
  element: <Component />,
  children: children,
});

export default GenerateRoute;
