import { SecurityContext } from '@virtuslab/react-oauth2';
import { ReactNode, useContext, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { GlobalConfig } from '../../../config/global';
import UserRoles from '../../../constants/userRoles';
import { AuthenticatedUserContext } from '../../../context/AuthenticatedUserContext';
import useSelfUpdatingRef from '../../../hooks/useSelfUpdatingRef';
import { createUrl } from '../../../services/url';
import { useLazyGetUserInfoQuery } from '../../../store/api/user/userInfoApi';
import { setAuthStateAction, setAuthUser, setAuthUserToken } from '../../../store/auth/actions';
import { RESPONSE_STATE } from '../../../store/types';

type AuthenticatedUserProviderProps = {
  config: GlobalConfig;
  children: ReactNode;
  onError?: () => void;
};

const CURRENT_USER = 'me';

/**
 * Provide bridge between @virtuslab/react-oauth2 and application store
 */
export const AuthenticatedUserProvider = ({ config, children }: AuthenticatedUserProviderProps) => {
  const securityContext = useContext(SecurityContext);

  const dispatch = useDispatch();
  const [fetchUser, { isUninitialized }] = useLazyGetUserInfoQuery();

  useEffect(() => {
    if (!securityContext?.auth) {
      return;
    }

    (async () => {
      try {
        await securityContext?.auth.isAuthenticating();
        const token = await securityContext?.auth.getAccessToken();

        if (!token) {
          dispatch(setAuthUser(undefined));

          return;
        }

        dispatch(setAuthUserToken(token));

        const profile = await (isUninitialized && fetchUser(CURRENT_USER).unwrap());

        if (!profile || !profile.id) {
          dispatch(setAuthUser(undefined));

          return;
        }

        dispatch(
          setAuthUser({
            firstName: profile.firstName!,
            lastName: profile.lastName!,
            email: profile.email!,
            id: profile.id!,
            permission: profile.isAdmin! ? UserRoles.Admin : UserRoles.User,
          }),
        );
      } catch (e) {
        dispatch(setAuthStateAction(RESPONSE_STATE.error));
      }
    })();
  }, [dispatch, securityContext?.auth, isUninitialized, fetchUser]);

  const context = useSelfUpdatingRef({
    login: (target: string) => {
      if (securityContext?.auth.isAuthenticated()) {
        return;
      }
      dispatch(setAuthStateAction(RESPONSE_STATE.loading));

      securityContext?.auth
        .login(createUrl(target === '/auth/logout' ? '/' : target, config.domain), 'google', 'login')
        .then(() => {
          dispatch(setAuthStateAction(RESPONSE_STATE.loaded));
        })
        .catch(() => {
          dispatch(setAuthStateAction(RESPONSE_STATE.error));
        });
    },
    logout: () => {
      if (!securityContext?.auth.isAuthenticated()) {
        return;
      }

      dispatch(setAuthStateAction(RESPONSE_STATE.loading));

      securityContext?.auth
        .logout()
        .then(() => {
          dispatch(setAuthUser(undefined));
          dispatch(setAuthUserToken(undefined));
        })
        .catch(() => {
          dispatch(setAuthStateAction(RESPONSE_STATE.error));
        });
    },
  });

  return <AuthenticatedUserContext.Provider value={context.current}>{children}</AuthenticatedUserContext.Provider>;
};
