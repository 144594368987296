import { createRouterReducer } from '@lagunovsky/redux-react-router';
import { combineReducers } from '@reduxjs/toolkit';
import { History } from 'history';

import { hotDesksApi } from './api/hotdesks/hotdesksApi';
import { mockAuthApi } from './api/mock-auth/mockAuthApi';
import { officesApi } from './api/offices/officesApi';
import { parkingsApi } from './api/parkings/parkingsApi';
import { userInfoApi } from './api/user/userInfoApi';
import auth from './auth';

const isLocalDevelopment = process.env.REACT_APP_ENV === 'local';

export const createRootReducer = (history: History) =>
  combineReducers({
    router: createRouterReducer(history),
    auth,
    [officesApi.reducerPath]: officesApi.reducer,
    [parkingsApi.reducerPath]: parkingsApi.reducer,
    [userInfoApi.reducerPath]: userInfoApi.reducer,
    [hotDesksApi.reducerPath]: hotDesksApi.reducer,

    ...(isLocalDevelopment && {
      [mockAuthApi.reducerPath]: mockAuthApi.reducer,
    }),
  });

export type RootState = ReturnType<ReturnType<typeof createRootReducer>>;
