import { useContext } from 'react';
import { useSelector } from 'react-redux';

import { AuthenticatedUserContext } from '../context/AuthenticatedUserContext';
import { isAdminSelector, isAuthenticatedSelector, userSelector } from '../store/auth/selectors';

export const useAuthenticatedUser = () => {
  const { login, logout } = useContext(AuthenticatedUserContext);

  const isAdmin = useSelector(isAdminSelector);
  const isAuthenticated = useSelector(isAuthenticatedSelector);
  const user = useSelector(userSelector);

  return {
    login,
    logout,
    isAdmin,
    isAuthenticated,
    user,
  };
};
