import shouldForwardProp from '@styled-system/should-forward-prop';
import styled, { css } from 'styled-components/macro';

import palette from '../../../theme/palette';
import type { Size } from '../../../theme/typography';
import { lineHeights, weights } from '../../../theme/typography';

export const variants = {
  primary: {
    normal: { default: palette.greyscale[90], disabled: palette.greyscale[40] },
    inverted: {
      default: palette.inverted.white,
      disabled: palette.greyscale[40],
    },
  },
  secondary: {
    normal: { default: palette.greyscale[60], disabled: palette.greyscale[40] },
    inverted: {
      default: palette.inverted[80],
      disabled: palette.greyscale[40],
    },
  },
  tertiary: {
    normal: { default: palette.greyscale[40], disabled: palette.greyscale[40] },
    inverted: {
      default: palette.inverted.white,
      disabled: palette.greyscale[40],
    },
  },
  active: {
    normal: {
      default: palette.support.success[60],
      disabled: palette.greyscale[40],
    },
    // same as normal
    inverted: {
      default: palette.support.success[60],
      disabled: palette.greyscale[40],
    },
  },
  alert: {
    normal: {
      default: palette.support.alert[60],
      disabled: palette.greyscale[40],
    },
    // same as normal
    inverted: {
      default: palette.support.alert[60],
      disabled: palette.greyscale[40],
    },
  },
  info: {
    normal: {
      default: palette.support.info[60],
      disabled: palette.greyscale[40],
    },
    // same as normal
    inverted: {
      default: palette.support.info[60],
      disabled: palette.greyscale[40],
    },
  },
  warning: {
    normal: {
      default: palette.support.warning[60],
      disabled: palette.greyscale[40],
    },
    inverted: {
      default: palette.support.info[60],
      disabled: palette.greyscale[40],
    },
  },
  success: {
    normal: {
      default: palette.support.success[60],
      disabled: palette.greyscale[40],
    },
    // same as normal
    inverted: {
      default: palette.support.success[60],
      disabled: palette.greyscale[40],
    },
  },
} as const;

export const styleVariants = {
  600: { weight: weights.regular, lineHeight: lineHeights.sm },
  500: { weight: weights.regular, lineHeight: lineHeights.xl },
  400: { weight: weights.regular, lineHeight: lineHeights.lg },
  300: { weight: weights.regular, lineHeight: lineHeights.md },
  200: { weight: weights.regular, lineHeight: lineHeights.sm },
} as const;

export type Variant = keyof typeof variants;

export type Props = Readonly<{
  variant?: Variant;
  size: Exclude<Size, 100 | 700>;
  disabled?: boolean;
  inverted?: boolean;
  textAlign?: 'center' | 'justify' | 'left' | 'right';
  textDecoration?: 'line-through' | 'none';
  textTransform?: 'capitalize';
  weight?: keyof typeof weights;
  fullWidth?: boolean;
}>;

const bodyColor = ({ variant = 'primary', disabled = false, inverted = false }: Props) => {
  const colorVariants = variants[variant];

  const colorVariant = inverted ? colorVariants.inverted : colorVariants.normal;

  const color = disabled ? colorVariant.disabled : colorVariant.default;

  return css`
    color: ${color};
  `;
};

const bodyCursor = ({ disabled = false }: Props) => css`
  cursor: ${disabled ? 'not-allowed' : null};
`;

const Body = styled.span.withConfig({
  shouldForwardProp: (prop) => shouldForwardProp(prop),
})<Props>`
  display: inline-flex;
  font-size: ${({ theme, size }) => theme.scTheme.fontSizes[size]};
  text-align: ${(props) => props.textAlign ?? 'left'};
  text-decoration: ${(props) => props.textDecoration};
  text-transform: ${(props) => props.textTransform};
  ${bodyColor}
  ${bodyCursor}
  ${({ size, weight }) => css`
    font-weight: ${weight ? weights[weight] : undefined ?? styleVariants[size].weight};
    line-height: ${styleVariants[size].lineHeight};
  `}
  ${({ fullWidth }) => (fullWidth ? 'width: 100%;' : '')}
`;

export default Body;
