import { RESPONSE_STATE } from '../types';

export type LoggedInUser = {
  firstName: string;
  lastName: string;
  id: string;
  email: string;
  permission: string;
  avatar?: string | null;
};

export type AuthState = Readonly<{
  user?: LoggedInUser;
  state?: RESPONSE_STATE;
  token?: string;
}>;

export const initialState: AuthState = {
  user: undefined,
  state: RESPONSE_STATE.notInitialized,
  token: undefined,
} as const;
