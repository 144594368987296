import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { FC, PropsWithChildren } from 'react';

import ThemeProvider from '../components/organisms/ThemeProvider';
import { BreakpointsProvider } from './BreakpointsProvider';

export const ThemeProviders: FC<PropsWithChildren> = ({ children }) => (
  <LocalizationProvider dateAdapter={AdapterLuxon}>
    <ThemeProvider>
      <BreakpointsProvider>{children}</BreakpointsProvider>
    </ThemeProvider>
  </LocalizationProvider>
);
