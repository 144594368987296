import type { ButtonProps } from '@mui/material/Button';
import MuiButton from '@mui/material/Button';
import type { ForwardedRef, ReactElement } from 'react';
import { forwardRef } from 'react';
import styled, { css } from 'styled-components/macro';

import palette from '../../../theme/palette';
import shadows from '../../../theme/shadows';
import ActionText, { variants as textVariants } from '../ActionText';

const variants = {
  primary: {
    default: palette.primary[60],
    hover: palette.primary[70],
    active: palette.primary[80],
    shadow: shadows.focus.primary.full,
  },
  alert: {
    default: palette.support.alert[60],
    hover: palette.support.alert[70],
    active: palette.support.alert[80],
    shadow: shadows.focus.alert.full,
  },
};

type Variant = keyof typeof variants;

type StyledButtonProps = Readonly<{
  disabled: boolean;
  buttonVariant: Variant;
}>;

const buttonColors = ({ disabled, buttonVariant }: StyledButtonProps) => {
  if (disabled) {
    return css`
      &,
      &:hover {
        background-color: ${({ theme }) => theme.scTheme.palette.greyscale[20]};
      }
    `;
  }

  return css`
    background-color: ${variants[buttonVariant].default};

    &:hover {
      background-color: ${variants[buttonVariant].hover};
    }

    &:active {
      background-color: ${variants[buttonVariant].active};
    }

    &:focus-visible {
      box-shadow: ${variants[buttonVariant].shadow};
    }

    ${ActionText} {
      &:hover,
      &:active {
        color: ${textVariants.primary.inverted.default};
      }
    }
  `;
};

const Button = styled(MuiButton).withConfig<StyledButtonProps>({
  shouldForwardProp: (prop) => prop !== 'buttonVariant',
})`
  &&& {
    @media ${({ theme }) => theme.scTheme.mediaQueries.mobile} {
      & {
        padding: 8px;
        min-width: 0;
      }
    }

    ${({ disabled = false }) =>
      disabled &&
      css`
        cursor: not-allowed;
      `}

    ${buttonColors}

    &.MuiButtonBase-root {
      .Mui-disabled {
        color: unset;
        pointer-events: all;
      }
    }
  }
`;

export type Props = Pick<
  ButtonProps,
  'children' | 'disabled' | 'endIcon' | 'fullWidth' | 'onClick' | 'ref' | 'startIcon' | 'type'
> &
  Readonly<{
    variant?: Variant;
  }>;

const CTAButton = forwardRef(
  (
    { children, disabled = false, type, fullWidth, onClick, startIcon, endIcon, variant = 'primary' }: Props,
    ref: ForwardedRef<HTMLButtonElement>,
  ): ReactElement => (
    <Button
      disabled={disabled}
      type={type}
      fullWidth={fullWidth}
      color="primary"
      size="large"
      variant="contained"
      onClick={onClick}
      startIcon={startIcon}
      endIcon={endIcon}
      buttonVariant={variant}
      ref={ref}>
      <ActionText
        inverted
        disabled={disabled}>
        {children}
      </ActionText>
    </Button>
  ),
);

export default CTAButton;
