import { Box } from '@mui/material';
import Divider from '@mui/material/Divider';
import { Info } from 'phosphor-react';
import type { FC, ReactElement } from 'react';
import styled from 'styled-components/macro';

import type { NavGroup } from '../../../config/navigation';
import { useBreakpoints } from '../../../hooks/useBreakpoint';
import palette from '../../../theme/palette';
import { MenuButton } from '../../atoms/MenuButton';
import Body from '../../molecules/Body';
import Icon from '../../molecules/Icon';
import NavButton from '../../molecules/NavButton';
import NavGroupTitle from '../../molecules/NavGroupTitle';
import OmLogo from '../../molecules/OmLogo';

const NavSidebarWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

const NavLinksGroup = styled.div`
  margin-bottom: ${({ theme }) => theme.scTheme.space[600]};
  padding: 0 ${({ theme }) => theme.scTheme.space[300]};

  & > * {
    margin-bottom: ${({ theme }) => theme.scTheme.space[200]};
    margin-top: ${({ theme }) => theme.scTheme.space[200]};
    padding-bottom: calc(${({ theme }) => `${theme.scTheme.space[300]} + ${theme.scTheme.space[100]}`});
    padding-right: 0;
    padding-top: calc(${({ theme }) => `${theme.scTheme.space[300]} + ${theme.scTheme.space[100]}`});
  }

  & > *:last-child {
    margin-bottom: 0;
  }

  & > *:first-child {
    margin-bottom: 0;
    margin-top: 0;
  }
`;

const SidebarHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${({ theme }) => theme.scTheme.space[800]};

  margin-top: ${({ theme }) => theme.scTheme.space[600]};
  margin-right: ${({ theme }) => theme.scTheme.space[600]};
  margin-left: ${({ theme }) => theme.scTheme.space[600]};

  @media ${({ theme }) => theme.scTheme.mediaQueries.mobile} {
    margin-top: ${({ theme }) => theme.scTheme.space[500]};
    margin-right: ${({ theme }) => theme.scTheme.space[500]};
    margin-left: ${({ theme }) => theme.scTheme.space[500]};
  }
`;

const LegalClauseContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  margin: ${({ theme }) => theme.scTheme.space[600]};
  gap: ${({ theme }) => theme.scTheme.space[400]};
`;

const LegalClauseLink = styled.a`
  color: #2a7e55;
  font-size: ${({ theme }) => theme.scTheme.fontSizes[200]};
  font-weight: ${({ theme }) => theme.scTheme.fontWeights.medium};
`;

type NavigationMenuListProps = {
  options: NavGroup[];
  profileLink: ReactElement;
  onClose: () => void;
};

export const NavigationMenuList: FC<NavigationMenuListProps> = ({ options, profileLink, onClose }) => {
  const { isMobile } = useBreakpoints();

  return (
    <NavSidebarWrapper>
      <Box overflow="auto">
        <SidebarHeaderContainer>
          <OmLogo />

          {isMobile && (
            <MenuButton
              variant="close"
              onClick={onClose}
            />
          )}
        </SidebarHeaderContainer>
        {options.map((group) => (
          <NavLinksGroup key={group.id}>
            {group.title ? (
              <NavGroupTitle
                title={group.title}
                key={group.id}
              />
            ) : null}
            {group.items.map((item) => (
              <NavButton
                item={item}
                key={item.id}
                changeOnActive
                onClick={onClose}
              />
            ))}
          </NavLinksGroup>
        ))}
      </Box>
      <Box mt="auto">
        <LegalClauseContainer>
          <Icon size="md">
            <Info
              color={palette.support.info[60]}
              weight="fill"
            />
          </Icon>

          <Body size={200}>
            Processing as a legitimate interest to ensure the safety and organisation of the work at VL, basic data:
            first name, last name, email address, registration number, duration: up to 90 days.
          </Body>
          <LegalClauseLink href="https://virtuslab.com/privacy-policy/">Read full GDPR clause</LegalClauseLink>
        </LegalClauseContainer>

        <Divider />
        {profileLink}
      </Box>
    </NavSidebarWrapper>
  );
};
