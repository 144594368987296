import { createSelector } from '@reduxjs/toolkit';
import isNil from 'lodash/isNil';

import UserRoles from '../../constants/userRoles';
import { RootState } from '../rootReducer';
import { AuthState } from './initialState';

const select = (state: RootState): AuthState => state.auth;

export const isAuthenticatedSelector = createSelector(select, (state) => !isNil(state.token));
export const isAdminSelector = createSelector(select, (state) => state.user?.permission === UserRoles.Admin);
export const userSelector = createSelector(select, (state) => state.user);
export const tokenSelector = createSelector(select, (state) => state.token);
