import styled, { css } from 'styled-components/macro';

import palette from '../../../theme/palette';
import type { Size } from '../../../theme/typography';
import { lineHeights, weights } from '../../../theme/typography';

export const variants = {
  primary: {
    normal: {
      default: palette.primary[60],
      hover: palette.primary[70],
      active: palette.primary[80],
      disabled: palette.greyscale[40],
    },
    inverted: {
      default: palette.inverted[100],
      hover: palette.inverted[90],
      active: palette.inverted[80],
      disabled: palette.inverted[40],
    },
  },
  secondary: {
    normal: {
      default: palette.greyscale[60],
      hover: palette.greyscale[70],
      active: palette.greyscale[80],
      disabled: palette.greyscale[40],
    },
    inverted: {
      default: palette.inverted[100],
      hover: palette.inverted[90],
      active: palette.inverted[80],
      disabled: palette.inverted[40],
    },
  },
  destructive: {
    normal: {
      default: palette.support.alert[60],
      hover: palette.support.alert[70],
      active: palette.support.alert[80],
      disabled: palette.greyscale[40],
    },
    inverted: {
      default: palette.inverted[100],
      hover: palette.inverted[90],
      active: palette.inverted[80],
      disabled: palette.inverted[40],
    },
  },
  ghost: {
    normal: {
      default: palette.greyscale[90],
      hover: palette.greyscale[90],
      active: palette.greyscale[100],
      disabled: palette.greyscale[40],
    },
    inverted: {
      default: palette.inverted[100],
      hover: palette.inverted[90],
      active: palette.inverted[80],
      disabled: palette.inverted[40],
    },
  },
} as const;

const styleVariants = {
  200: { weight: weights.medium, lineHeight: lineHeights.md },
  300: { weight: weights.medium, lineHeight: lineHeights.md },
} as const;

export type Props = Readonly<{
  variant?: keyof typeof variants;
  size?: Extract<Size, 200 | 300>;
  disabled?: boolean;
  inverted?: boolean;
}>;

const actionTextCursor = ({ disabled = false }: Props) => css`
  cursor: ${disabled ? 'not-allowed' : null};
`;

const actionTextColors = ({ variant = 'primary', inverted = false, disabled = false }: Props) => {
  const colorVariants = variants[variant];

  const colorVariant = inverted ? colorVariants.inverted : colorVariants.normal;

  if (disabled) {
    return css`
      color: ${colorVariant.disabled};
    `;
  }

  return css`
    color: ${colorVariant.default};

    &:hover {
      color: ${colorVariant.hover};
    }

    &:active {
      color: ${colorVariant.active};
    }
  `;
};

const actionTextSize = ({ size = 300 }: Props) => css`
  font-size: ${({ theme }) => theme.scTheme.fontSizes[size]};
  font-weight: ${styleVariants[size].weight};
  line-height: ${styleVariants[size].lineHeight};
`;

const ActionText = styled.span<Props>`
  display: inline-flex;
  ${actionTextSize}
  ${actionTextColors}
  ${actionTextCursor}
  transition: color ${({ theme }) => theme.scTheme.animations.durations.medium};
`;

export default ActionText;
