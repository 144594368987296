import type { SerializedError } from '@reduxjs/toolkit';
import type { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';

type Response = FetchBaseQueryError | SerializedError | undefined;

const getErrorMessage = (response: Response) => {
  if (response && 'data' in response && hasMessages(response.data)) {
    return response.data.messages;
  }

  return [DEFAULT_MESSAGE];
};

const hasMessages = (x: any): x is { messages: string[] } => {
  if (Array.isArray(x?.messages)) {
    return true;
  }

  return false;
};

const DEFAULT_MESSAGE = 'Server error happened';

export default getErrorMessage;
