import { createApi } from '@reduxjs/toolkit/query/react';

import getBaseQuery from '../../../services/getBaseQuery';

export type User = {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  isAdmin: boolean;
};

export const userInfoApi = createApi({
  reducerPath: 'userInfoApi',
  baseQuery: getBaseQuery('users'),
  endpoints: (builder) => ({
    getUserInfo: builder.query<User, string>({
      query: (id: string) => ({
        path: `${id}`,
      }),
    }),
  }),
});

export const { useLazyGetUserInfoQuery } = userInfoApi;
