export const breakpoints = {
  xs: 0,
  sm: 768,
  md: 1304,
  lg: 1304,
  xl: 1304,
} as const;

export const mediaQueries = {
  mobile: `only screen and (min-width: ${breakpoints.xs}px) and (max-width: ${breakpoints.sm - 1}px)`,
  tablet: `only screen and (min-width: ${breakpoints.sm}px)`,
  desktop: `only screen and (min-width: ${breakpoints.md}px)`,
} as const;

export type Breakpoint = keyof typeof breakpoints;
export type MediaQuery = keyof typeof mediaQueries;
