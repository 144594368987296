import { useEffect, useState } from 'react';
import { RouteObject, useLocation, useRoutes as useRoutesRenderer } from 'react-router-dom';

import { CommonRoutes, DefaultRoutes } from '../constants/routes/COMMON_ROUTES';
import { Route } from '../constants/routes/types';
import generateRoute from '../services/generateRoute';
import { useAuthenticatedUser } from './useAuthenticatedUser';

const assignChildren = async (userPermissions: string) =>
  await import(`../constants/routes/${userPermissions}_ROUTES`).then((modules) => ({
    ...DefaultRoutes,
    children: modules.default.map((route: Route) => generateRoute(route)),
  }));

const generateRoutes = async (permission: string | undefined): Promise<RouteObject[]> => {
  if (!permission) {
    return CommonRoutes.map((route) => generateRoute(route));
  }

  const roleRoutes = await assignChildren(permission);

  return [roleRoutes, ...CommonRoutes].map(generateRoute);
};

const useRoutes = () => {
  const location = useLocation();
  const { user, isAuthenticated } = useAuthenticatedUser();
  const [routes, setRoutes] = useState<RouteObject[]>([]);

  useEffect(() => {
    const getRoutes = async () => {
      const data = await generateRoutes(user?.permission);

      setRoutes(data);
    };

    getRoutes();
  }, [user?.permission]);

  const renderer = useRoutesRenderer(routes);

  if (isAuthenticated && CommonRoutes.length === routes.length) {
    return {
      isAuthenticated,
      pathname: location.pathname,
    };
  }

  return {
    isAuthenticated,
    renderer,
    pathname: location.pathname,
  };
};

export default useRoutes;
