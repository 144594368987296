import shouldForwardProp from '@styled-system/should-forward-prop';
import styled, { css } from 'styled-components/macro';

import palette from '../../../theme/palette';

const variants = {
  subtle: {
    grey: {
      normal: { color: palette.greyscale[10] },
      inverted: { color: palette.inverted.white },
    },
    green: {
      normal: { color: palette.support.success[10] },
      inverted: { color: palette.inverted.white },
    },
    red: {
      normal: { color: palette.support.alert[10] },
      inverted: { color: palette.inverted.white },
    },
    blue: {
      normal: { color: palette.support.info[10] },
      inverted: { color: palette.inverted.white },
    },
    orange: {
      normal: { color: palette.support.warning[10] },
      inverted: { color: palette.inverted.white },
    },
  },
  strong: {
    grey: {
      normal: { color: palette.greyscale[60] },
      inverted: { color: palette.inverted.white },
    },
    green: {
      normal: { color: palette.support.success[60] },
      inverted: { color: palette.inverted.white },
    },
    red: {
      normal: { color: palette.support.alert[60] },
      inverted: { color: palette.inverted.white },
    },
    blue: {
      normal: { color: palette.support.info[60] },
      inverted: { color: palette.inverted.white },
    },
    orange: {
      normal: { color: palette.support.warning[60] },
      inverted: { color: palette.inverted.white },
    },
  },
} as const;

export type Variant = keyof typeof variants;
export type Color = keyof typeof variants[Variant];

type Props = Readonly<{
  inverted?: boolean;
  hasIcon?: boolean;
  variant?: Variant;
  fullWidth?: boolean;
  color: Color;
}>;

const badgePaddings = ({ hasIcon = false }: Props) => {
  if (hasIcon) {
    return css`
      padding: ${({ theme }) => theme.scTheme.space[200]};
      padding-left: ${({ theme }) => theme.scTheme.space[300]};
    `;
  }

  return css`
    padding: ${({ theme }) => theme.scTheme.space[200]} ${({ theme }) => theme.scTheme.space[300]};
  `;
};

const BadgeBase = styled.div.withConfig<Props>({ shouldForwardProp })`
  width: ${({ fullWidth = false }) => (fullWidth ? '100%' : 'max-content')};
  height: max-content;
  display: flex;
  align-items: center;
  ${badgePaddings};
  text-transform: uppercase;
  border-radius: ${({ theme }) => theme.scTheme.borders.radii.md};
  background-color: ${({ variant = 'subtle', color, inverted = false }) =>
    variants[variant][color][inverted ? 'inverted' : 'normal'].color};
`;

export default BadgeBase;
