import { Suspense } from 'react';
import { Navigate } from 'react-router-dom';

import useRoutes from '../../../hooks/useRoutes';
import Loading from '../../molecules/Loading';

const RenderRoutes = () => {
  const { isAuthenticated, pathname, renderer } = useRoutes();

  if (!isAuthenticated && pathname !== '/auth/login') {
    return <Navigate to={`/auth/login?redirectUri=${pathname}`} />;
  }

  return <Suspense fallback={<Loading />}>{renderer}</Suspense>;
};

export default RenderRoutes;
