import isNil from 'lodash/isNil';
import type { ReactElement, ReactNode } from 'react';
import styled, { css } from 'styled-components/macro';

import type { Space } from '../../../theme/space';

type TextGroupContainerProps = Readonly<{
  gap: Space;
  hideOverflow: boolean;
  align?: 'left' | 'right';
}>;

const textGroupAlignment = ({ align }: TextGroupContainerProps) => {
  if (isNil(align)) {
    return null;
  }

  if (align === 'right') {
    return css`
      justify-items: end;
    `;
  }

  return css`
    justify-items: start;
  `;
};

const textGroupGap = ({ gap }: TextGroupContainerProps) => css`
  row-gap: ${({ theme }) => theme.scTheme.space[gap]};
`;

const TextGroupContainer = styled.div<TextGroupContainerProps>`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  ${textGroupGap};
  ${textGroupAlignment};
  ${({ hideOverflow }) =>
    hideOverflow &&
    css`
      > * {
        display: inline-block;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    `}
`;

export type Props = Readonly<{
  children: ReactNode;
  hideOverflow?: boolean;
  gap?: Space;
  align?: TextGroupContainerProps['align'];
}>;

const TextGroup = ({ children, hideOverflow = false, gap = 100, align }: Props): ReactElement => (
  <TextGroupContainer
    gap={gap}
    hideOverflow={hideOverflow}
    align={align}>
    {children}
  </TextGroupContainer>
);

export default TextGroup;
