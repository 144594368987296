import styled from 'styled-components';

export const MobileMenuButtonContainer = styled.div`
  position: absolute;
  z-index: ${({ theme }) => theme.scTheme.zindex.floating};
  top: 0;
  left: 0;
  display: flex;
  padding: ${({ theme }) => theme.scTheme.space[500]};
`;

export const MobileContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 100%;
  width: ${({ theme }) => theme.scTheme.widths.default.navbar};

  background-color: white;
  border-right: ${({ theme }) => theme.scTheme.borders.styles.thin.grey[20]};
`;

export const DesktopContentContainer = styled.div`
  flex-shrink: 0;
  height: 100%;
  width: ${({ theme }) => theme.scTheme.widths.default.navbar};
  border-right: ${({ theme }) => theme.scTheme.borders.styles.thin.grey[20]};
`;
