import MuiAvatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import shouldForwardProp from '@styled-system/should-forward-prop';
import add from 'lodash/fp/add';
import first from 'lodash/fp/first';
import type { ReactElement, ReactNode } from 'react';
import { memo } from 'react';
import styled, { css } from 'styled-components/macro';

import palette from '../../../theme/palette';
import Header from '../Header';

const colors = [
  palette.avatars.marguerite,
  palette.avatars.paradiso,
  palette.avatars.cerise,
  palette.avatars.sharon,
  palette.avatars.saintTropez,
  palette.avatars.corn,
];

export const variants = {
  md: {
    headerSize: 'h5',
    size: '4rem',
  },
  sm: {
    headerSize: 'h7',
    size: '2.4rem',
  },
  xs: {
    headerSize: 'h7',
    size: '2rem',
  },
} as const;

type Variant = keyof typeof variants;

type AvatarProps = Readonly<{
  sizeVariant: Variant;
  colorIndex: number;
  outlined: boolean;
  disabled: boolean;
}>;

const avatarSizes = (props: AvatarProps) => {
  const { size } = variants[props.sizeVariant];

  if (props.outlined) {
    return css`
      height: calc(2 * ${({ theme }) => theme.scTheme.space[100]} + ${size});
      width: calc(2 * ${({ theme }) => theme.scTheme.space[100]} + ${size});
    `;
  }

  return css`
    height: ${size};
    width: ${size};
  `;
};

const avatarColors = (props: AvatarProps) => css`
  background-color: ${colors[props.colorIndex]};
  ${props.disabled &&
  css`
    filter: grayscale(1);
  `}
`;

const avatarOutline = (props: AvatarProps) =>
  props.outlined &&
  css`
    border: ${({ theme }) => theme.scTheme.borders.styles.medium.inverted.white};
    border-radius: 50%;
  `;

const StyledAvatar = styled(MuiAvatar).withConfig({
  shouldForwardProp: (prop) => shouldForwardProp(prop),
})`
  &&& {
    align-items: center;
    display: flex;
    position: relative;
    ${avatarColors}
    ${avatarSizes}
    ${avatarOutline}

    text-transform: uppercase;
  }
`;

const AvatarBadge = styled.div`
  background: ${({ theme }) => theme.scTheme.palette.greyscale[10]};
  border: ${({ theme }) => theme.scTheme.borders.styles.medium.inverted.white};
  border-radius: 50%;
  left: 60%;
  position: absolute;
  top: 60%;
`;

type User = Readonly<{
  avatar?: string | null;
  firstName: string;
  id: string;
  lastName: string;
}>;

export type Props = Readonly<{
  outlined?: boolean;
  variant?: Variant;
  user: User;
  badge?: ReactNode;
  overlay?: ReactNode;
  disabled?: boolean;
}>;

const charToCode = (char: string) => char.charCodeAt(0);

const Avatar = memo(
  ({ user, variant = 'md', badge, outlined = false, overlay, disabled = false }: Props): ReactElement => {
    const colorIndex = user.id.split('').map(charToCode).reduce(add, 0) % colors.length;

    return (
      <Box position="relative">
        {overlay}
        <StyledAvatar
          disabled={disabled}
          sizeVariant={variant}
          colorIndex={colorIndex}
          src={user.avatar ?? undefined}
          alt={`${user.firstName} ${user.lastName}`}
          outlined={outlined}>
          <Header
            as={variants[variant].headerSize}
            inverted>
            {first(user.firstName)}
            {variant !== 'xs' && first(user.lastName)}
          </Header>
        </StyledAvatar>
        {badge !== undefined && <AvatarBadge>{badge}</AvatarBadge>}
      </Box>
    );
  },
);

export default Avatar;
