import MuiDrawer from '@mui/material/Drawer';
import { FC, PropsWithChildren, ReactElement, RefObject, useRef, useState } from 'react';

import { NavGroup } from '../../../config/navigation';
import { useBreakpoints } from '../../../hooks/useBreakpoint';
import { MenuButton } from '../../atoms/MenuButton/menu-button';
import { DesktopContentContainer, MobileContentContainer, MobileMenuButtonContainer } from './navigation-menu.styled';
import { NavigationMenuList } from './navigation-menu-list';

export type NavigationMenuProps = {
  options: NavGroup[];
  profileLink: ReactElement;
};

export const NavigationMenu: FC<NavigationMenuProps> = ({ options, profileLink }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const modalRef = useRef<HTMLDivElement>(null);

  const toggleMenuOpen = () => setIsMenuOpen((x) => !x);
  const handleMenuClose = () => setIsMenuOpen(false);

  const { isMobile } = useBreakpoints();

  const BreakpointContainer = isMobile ? MobileContainer : DesktopContainer;

  return (
    <>
      {isMobile && (
        <MobileMenuButtonContainer>
          <MenuButton
            variant="open"
            onClick={toggleMenuOpen}
          />
        </MobileMenuButtonContainer>
      )}

      <BreakpointContainer
        isMenuOpen={isMenuOpen}
        modalRef={modalRef}
        onClose={handleMenuClose}>
        <NavigationMenuList
          options={options}
          profileLink={profileLink}
          onClose={handleMenuClose}
        />
      </BreakpointContainer>
    </>
  );
};

type MobileContainerProps = {
  isMenuOpen: boolean;
  modalRef: RefObject<HTMLDivElement>;
  onClose: () => void;
};

const MobileContainer: FC<PropsWithChildren<MobileContainerProps>> = ({ isMenuOpen, modalRef, children, onClose }) => (
  <MuiDrawer
    open={isMenuOpen}
    ref={modalRef}
    onClose={onClose}>
    <MobileContentContainer>{children}</MobileContentContainer>
  </MuiDrawer>
);

const DesktopContainer: FC<PropsWithChildren> = ({ children }) => (
  <DesktopContentContainer>{children}</DesktopContentContainer>
);
