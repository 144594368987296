import { createContext, FC, ReactNode, useCallback, useEffect, useMemo, useState } from 'react';

import { breakpoints, MediaQuery } from '../theme/responsivness';

type BreakpointsState = Record<MediaQuery, boolean>;

export const BreakpointsContext = createContext<BreakpointsState | null>(null);

export const BreakpointsProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [windowWidth, setWindowWidth] = useState(0);

  const handleResize = useCallback(() => setWindowWidth(window.innerWidth), []);

  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [handleResize]);

  const breakpointsState = useMemo(() => mapBreakpointsState(windowWidth), [windowWidth]);

  return <BreakpointsContext.Provider value={breakpointsState}>{children}</BreakpointsContext.Provider>;
};

const mapBreakpointsState = (windowWidth: number): BreakpointsState => ({
  mobile: windowWidth >= breakpoints.xs && windowWidth < breakpoints.sm,
  tablet: windowWidth >= breakpoints.sm,
  desktop: windowWidth >= breakpoints.md,
});
