import { X } from 'phosphor-react';
import type { ReactElement } from 'react';
import type { CloseButtonProps } from 'react-toastify';

import palette from '../../../theme/palette';
import Icon from '../Icon';
import IconButton from '../IconButton';

type Props = CloseButtonProps;

const ToastCloseButton = ({ closeToast }: Props): ReactElement => (
  <IconButton onClick={closeToast}>
    <Icon size="lg">
      <X fill={palette.greyscale[60]} />
    </Icon>
  </IconButton>
);

export default ToastCloseButton;
