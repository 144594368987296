import { createTheme, ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import { StyledEngineProvider } from '@mui/styled-engine';
import { IconContext } from 'phosphor-react';
import type { ReactElement, ReactNode } from 'react';
import { createGlobalStyle, ThemeProvider as StyledThemeProvider } from 'styled-components/macro';

import theme from '../../../theme';
import globalStyle from '../../../theme/globalStyle';
import { defaultIconProps } from '../../../theme/icons';
import muiThemeOverrides from '../../../theme/muiTheme';

const GlobalStyle = createGlobalStyle`
  ${globalStyle}
`;

const muiTheme = createTheme(muiThemeOverrides);

type Props = Readonly<{
  children: ReactNode;
}>;

const ThemeProvider = ({ children }: Props): ReactElement => (
  <StyledEngineProvider injectFirst>
    <MuiThemeProvider theme={muiTheme}>
      <StyledThemeProvider theme={{ scTheme: theme }}>
        <IconContext.Provider value={defaultIconProps}>
          <>
            <GlobalStyle />
            {children}
          </>
        </IconContext.Provider>
      </StyledThemeProvider>
    </MuiThemeProvider>
  </StyledEngineProvider>
);

export default ThemeProvider;
