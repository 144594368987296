import Box from '@mui/material/Box';
import isNil from 'lodash/isNil';
import type { ReactElement, ReactNode } from 'react';
import type { ToastContentProps, TypeOptions } from 'react-toastify';

import type { Variant } from '../Body';
import Body from '../Body';

const toastTypeToBodyVariant: Record<TypeOptions, Variant> = {
  default: 'primary',
  error: 'alert',
  info: 'info',
  success: 'success',
  warning: 'warning',
};

type Props = Partial<ToastContentProps> &
  Readonly<{
    text: ReactNode;
    buttons?: (props: Partial<ToastContentProps>) => ReactNode;
  }>;

const Toast = ({ text, buttons, ...props }: Props): ReactElement => {
  const { toastProps } = props;
  const { type = 'default' } = toastProps ?? {};

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between">
      <Body
        size={300}
        variant={toastTypeToBodyVariant[type]}>
        {text}
      </Body>
      {!isNil(buttons) && (
        <Box
          onClick={(e) => e.stopPropagation()}
          ml={500}>
          {buttons(props)}
        </Box>
      )}
    </Box>
  );
};

export default Toast;
