import { FC, PropsWithChildren } from 'react';

import { FeaturesContextProvider, FeaturesType } from '../context/FeaturesContext';

const features: FeaturesType = {
  hotDeskBookingNotes: false,
  multiSelection: false,
  parkingSpotBookingNotes: false,
};

export const FeaturesProvider: FC<PropsWithChildren> = ({ children }) => (
  <FeaturesContextProvider value={features}>{children}</FeaturesContextProvider>
);
