import { createRouterMiddleware } from '@lagunovsky/redux-react-router';
import { configureStore, PreloadedState } from '@reduxjs/toolkit';
import { History } from 'history';

import { hotDesksApi } from './api/hotdesks/hotdesksApi';
import { officesApi } from './api/offices/officesApi';
import { parkingsApi } from './api/parkings/parkingsApi';
import { userInfoApi } from './api/user/userInfoApi';
import { type RootState, createRootReducer } from './rootReducer';

const { NODE_ENV, REACT_APP_ENV } = process.env;
const isDevelopment = REACT_APP_ENV === 'local' || NODE_ENV === 'development';

export const getStore = (history: History, preloadedState: PreloadedState<RootState> = {}) => {
  const store = configureStore({
    devTools: isDevelopment,
    preloadedState,
    reducer: createRootReducer(history),
    middleware: (getDefaultMiddleware) => [
      ...getDefaultMiddleware().concat(
        parkingsApi.middleware,
        officesApi.middleware,
        userInfoApi.middleware,
        hotDesksApi.middleware,
      ),
      createRouterMiddleware(history),
    ],
  });

  return store;
};
