import { NavItem } from '../config/navigation';
import { Route } from '../constants/routes/types';

const generateNavItem = ({ title, id, path, icon, disabled }: Route): NavItem => ({
  title: title,
  id: id,
  url: path,
  icon: icon,
  disabled: disabled,
});

export default generateNavItem;
