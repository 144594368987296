import { BaseQueryFn } from '@reduxjs/toolkit/dist/query/react';
import { BaseQueryApi } from '@reduxjs/toolkit/src/query/baseQueryTypes';
import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import { Navigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import Toast from '../components/molecules/Toast';
import { BASE_URL } from '../store/api/config';
import type { RootState } from '../store/rootReducer';
import getErrorMessage from './getApiResponseMessage';

type QueryConfiguration = {
  readonly path?: string;
  readonly params?: Record<string, string>;
  readonly method?: AxiosRequestConfig['method'];
  readonly data?: AxiosRequestConfig['data'];
  readonly responseType?: AxiosRequestConfig['responseType'];
};

const getBaseQuery =
  (baseUrl: string = BASE_URL): BaseQueryFn<QueryConfiguration> =>
  async ({ path, method, data, params, responseType }: QueryConfiguration, { getState }: BaseQueryApi) => {
    // TODO: assertion
    const token = (getState() as RootState).auth.token;
    const url = `${BASE_URL}/${baseUrl}${path ? `/${path}` : ''}`;

    try {
      const result = await axios({
        method: method || 'get',
        data,
        params,
        responseType,
        url,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (responseType === 'blob') {
        //@ts-ignore
        const blobUrl = URL.createObjectURL(result.data);

        return { data: blobUrl };
      }

      return { data: result.data };
    } catch (axiosError) {
      const err = axiosError as AxiosError;

      if (err.response?.status === 404 && responseType === 'blob') {
        return {
          error: { status: err.response?.status, data: undefined },
        };
      }

      if (err.response?.status === 401) {
        toast(<Toast text={'You are not authorized to perform this action'} />, { type: 'error' });

        <Navigate to="/auth/logout" />;

        return {
          error: { status: err.response?.status, data: err.response?.data },
        };
      }

      toast(<Toast text={getErrorMessage(err.response)} />, { type: 'error' });

      return {
        error: { status: err.response?.status, data: err.response?.data },
      };
    }
  };

export default getBaseQuery;
