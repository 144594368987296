import type { ReactElement } from 'react';
import styled, { css } from 'styled-components/macro';

import animations from '../../../theme/animations';
import type { Size } from '../../../theme/icons';
import IconBox from '../../atoms/IconBox';

const svgIconTransition = () => css`
  transition: fill ${animations.durations.fast} ${animations.timingFunctions.medium};
`;

type IconProps = Readonly<{
  size: Size;
}>;

const StyledSvgIcon = styled.svg<IconProps>`
  display: inline-block;
  fill: currentcolor;
  flex-shrink: 0;
  ${svgIconTransition}
  user-select: none;

  &&& {
    ${(props) => css`
      height: ${({ theme }) => theme.scTheme.icons.sizes[props.size].icon};
      width: ${({ theme }) => theme.scTheme.icons.sizes[props.size].icon};
    `}
  }
`;

export type Props = Readonly<{
  /** Icon Element */
  children: ReactElement;
  size: Size;
}>;

const Icon = ({ children, size }: Props): ReactElement => (
  <IconBox size={size}>
    <StyledSvgIcon size={size}>{children}</StyledSvgIcon>
  </IconBox>
);

export default Icon;
