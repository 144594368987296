export function createUrl(path: string, domain: string): string {
  if (!document?.location) {
    throw new Error('Application is not ready for SSR!');
  }

  if (!domain) {
    throw new Error('Application domain was not set!');
  }

  return new URL(path, domain || `${document.location.protocol}//${document.location.host}`).toString();
}
